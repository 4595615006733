











import Vue from "vue";
import HelloUser from "@/components/shared/HelloUser.vue";
import SkillsWithGroups from "@/components/shared/SkillsWithGroups.vue";
import { mapActions, mapGetters } from "vuex";
import { GET_USER_DETAILS, USER_PROFILE } from "@/store/modules/auth/constants";
import { AI_SCORE_REPORT } from "@/store/modules/candidates/constants";

export default Vue.extend({
  name: "CandidateDashboard",
  components: {
    HelloUser,
    SkillsWithGroups
  },
  data() {
    return {};
  },
  async mounted() {
    if (!this.get_profile) await this.fetch_profile();
    console.log(this.get_profile);
  },
  computed: {
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS,
      get_profile: USER_PROFILE
    })
  },
  watch: {},
  methods: {
    ...mapActions("candidate", {
      get_ai_report: AI_SCORE_REPORT
    }),
    ...mapActions("auth", {
      fetch_profile: USER_PROFILE
    })
  }
});
